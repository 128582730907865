import React from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { getImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { BgImage } from "gbimage-bridge";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import LogoCloud from "../components/Repeating/LogoCloud";
import Testimonials from "../components/Repeating/Testimonials";
import CallToAction from "../components/Repeating/CTA";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
    li {
      ${tw`text-sm lg:text-base tracking-wider lg:tracking-normal`}
    }
  }
  h3 {
    ${tw`font-body normal-case tracking-wider	text-base font-bold`}
  }
  p {
    ${tw`text-sm lg:text-base`}
  }
`;

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const heroImages = [
    getImage(data.heroDesktop.childImageSharp.gatsbyImageData),
    {
      ...getImage(data.heroMobile.childImageSharp.gatsbyImageData),
      media: `(max-width: 767px)`,
    },
  ];

  return (
    <Layout headerStyle="overlap">
      <SearchEngineOptimization
        title="Hotel Disinfecting Services | Hotel Cleaning | HCS"
        description="Need quality hotel disinfecting services nationwide? Our experts can effectively disinfect and sanitize hotel, resort, and corporate environments."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="pt-48 md:pt-64 lg:pt-80 xl:pt-[490px] pb-14 md:pb-52 lg:pb-[415px] relative mb-8 lg:mb-0">
        <BgImage
          className="inset-0 w-full h-full bg-center"
          image={heroImages}
          style={{ position: "absolute" }}
        />
        <div className="container relative">
          <h1 className="mb-4 md:mb-4 md:max-w-[560px]">Hotel Disinfecting</h1>
          <p className="md:text-xl mb-0 md:max-w-sm">
            Our team uses industrial-strength disinfectants plus electrostatic
            spray technology to disinfect and sanitize surfaces, floors, and
            touchpoints.
          </p>
        </div>
      </section>

      <LogoCloud className="relative lg:-top-60 mb-16 md:-mb-6" />

      <section className="relative mb-20 md:mb-32">
        <div className="absolute top-0 w-full"></div>
        <div className="relative container">
          <ScrollWrapper className="grid lg:grid-cols-12 gap-y-16 lg:gap-x-24">
            <ScrollNavigation className="lg:col-start-1 lg:col-span-4">
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-1"}
                  title="About"
                  stripHash
                  className="no-underline"
                />
              </li>
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-2"}
                  title="What We Disinfect"
                  stripHash
                  className="no-underline"
                />
              </li>
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-3"}
                  title="What We Specialize In"
                  stripHash
                  className="no-underline"
                />
              </li>
            </ScrollNavigation>

            <ScrollContent className="lg:col-end-13 lg:col-span-8">
              <StyledContent>
                <div id="anchor-1" className="mb-14 lg:mb-16">
                  <h2>Prevention & Response</h2>
                  <p>
                    At Hotel Cleaning Services, our team of cleaning experts can
                    quickly and effectively disinfect and sanitize hotel,
                    resort, and corporate environments. We combine the use of
                    CDC and EPA-approved disinfectants with electrostatic spray
                    to eliminate 99.9% of pathogens such as the coronavirus
                    (COVID-19), norovirus, and cold and flu viruses.
                  </p>
                </div>

                <div id="anchor-2" className="mb-14 lg:mb-16">
                  <h2 className="text-mobile-4xl md:text-4xl">
                    What We Disinfect
                  </h2>
                  <p>
                    We go above and beyond to disinfect all high touchpoint
                    surfaces and areas. Our technicians are highly trained and
                    certified professionals who provide the most effective
                    disinfecting services for:
                  </p>
                  <ul className="!grid md:grid-cols-2 gap-x-4">
                    <div>
                      <li>Hotel Guestrooms</li>
                      <li>Lobbies / Sitting &amp; Waiting Areas</li>
                      <li>Hallways</li>
                      <li>Back &amp; Front of House Areas</li>
                      <li>Sales Counters</li>
                      <li>Retail Spaces</li>
                      <li>Stair Handrails</li>
                      <li>Water Fountains</li>
                      <li>Bathrooms</li>
                      <li>Shelving/Racks</li>
                      <li>Offices</li>
                    </div>
                    <div>
                      <li>Gyms-Fitness Equipment</li>
                      <li>Floors &amp; Walls</li>
                      <li>Warehouse/Inventory Spaces</li>
                      <li>Locker Rooms &amp; Showers</li>
                      <li>Schools/Classrooms</li>
                      <li>Entrance/Exit Doors</li>
                      <li>Daycare Facilities</li>
                      <li>Churches</li>
                      <li>Commercial Kitchens</li>
                      <li>Spas</li>
                      <li>Restaurants &amp; Bars</li>
                    </div>
                  </ul>
                </div>

                <div id="anchor-3">
                  <h2 className="text-mobile-4xl md:text-4xl">
                    What We Specialize In
                  </h2>
                  <h3>Coronavirus (COVID-19) Cleaning & Disinfecting</h3>
                  <p>
                    Having trusted disease and biohazard disinfecting
                    professionals by your side is an important measure you can
                    take to ensure the safety of employees and guests.
                  </p>

                  <p>
                    In addition to preventative measures that disinfect and
                    sanitize your spaces, we also provide rapid emergency
                    response 24/7. Our expert cleanup technicians will arrive
                    promptly in personal protective equipment (PPE) to
                    thoroughly disinfect your property.
                  </p>

                  <p>
                    We adhere to all OSHA regulations, and our technicians are
                    certified by the Cleaning Institute Management Standards
                    (CIMS) with honors in CIMS-GB (Green Building). We also
                    follow the most current guidelines outlined by the Centers
                    for Disease Control and Prevention (CDC).
                  </p>

                  <h3>Wellness Butler Program</h3>
                  <p>
                    This service provides you with a dedicated cleaning
                    professional, attired in the proper PPE, to roam throughout
                    your property systematically. 
                  </p>

                  <p>
                    Your ‘Wellness Butler’ will proactively disinfect all guest
                    and employee points of contact in the main lobby, guest
                    entrances, guest restrooms, and public areas. Areas of
                    specific focus include door handles, handrails, desks and
                    tabletops, keyboards and screens, and all other areas
                    frequented and touched by your guests.
                  </p>

                  <p>
                    Our Wellness Butlers use the most advanced cleaners and
                    disinfectants to mitigate the spread of COVID-19 and other
                    pathogens. 
                  </p>

                  <p>
                    And if you join our Wellness Butler program, you are free to
                    market your hotel or resort as having this safety protocol
                    in place for guests.
                  </p>
                  <h3>Electrostatic Spray Technology</h3>
                  <p>
                    Electrostatic technology allows us to effectively sanitize
                    and disinfect your facility in a timely manner, making it
                    cost-effective for you. 
                  </p>

                  <p>
                    With this method, an electrostatic mist is released inside
                    an area, and it adheres to the tops, sides, front, and
                    bottoms of all surfaces. 
                  </p>

                  <p>
                    Used in conjunction with a specially formulated disinfectant
                    cleaner, it’s a one-step process that kills 99.9% of
                    bacteria in 10 minutes or less.
                  </p>

                  <iframe
                    width="100%"
                    height="315"
                    className="max-w-xl mt-10"
                    src="https://www.youtube.com/embed/nOSOf-5JR1o"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </StyledContent>
            </ScrollContent>
          </ScrollWrapper>
        </div>
      </section>

      <Testimonials />
      <CallToAction
        heading={["Contact ", <span className="text-primary-500">Us</span>]}
        subtext="Our service will provide you with a level of comfort, knowing that your customers and employees are in a safe and sanitary environment. Contact us today!"
      />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Hotel Disinfecting.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Hotel Disinfecting.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(
      relativePath: {
        eq: "services/2.1 Hotel Disinfecting/1.0 Disinfecting Hero Desktop.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    heroMobile: file(
      relativePath: {
        eq: "services/2.1 Hotel Disinfecting/1.0 Disinfecting Hero mobile.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
export default Page;
